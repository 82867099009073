<template>
  <div class="video-container">
    <video autoplay loop muted playsinline class="fullscreen-video">
      <source src="@/assets/video/bg_star.mp4" type="video/mp4">
    </video>
    <div class="content-overlay">
      <div class="text-center text-content">
        <h4 class="display-2 fw-medium">서비스 이용에 불편을 드려 죄송합니다.</h4>
        <h1 class="text-uppercase">멀티로 게임을 할 수 없습니다. 관리자에게 문의해주시길 바랍니다.</h1>
        <h6 class="display-2 fw-medium">현재 서비스 안정과 성능 향상을 위한 점검을 실시하고 있으며, 점검중에는 모든 서비스의 이용이 제한됩니다. 점검 후 더 나은 모습으로 찾아뵙겠습니다.</h6>
        <button class="error-button" @click="goToHome">
          <a class="error-link" rel="">홈으로 이동</a>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Error',
  computed: {
    logoutData() {
      return this.$route.query.logoutData; // URL 쿼리에서 전달된 데이터 가져오기
    }
  },
  methods: {
    goToHome() {
        // 전달받은 logoutData URL로 이동
        window.location.href = this.logoutData;
    }
  }
}
</script>
<style scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 9999;
}

.fullscreen-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  transform: translate(-50%, -50%);
  background-size: cover;
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  text-align: center;
}

.text-content {
  max-width: 100%; /* 원하는 최대 폭 설정 */
  color: white; /* 텍스트 색상 설정 */
  text-align: center;
  padding: 20px;
}

h6 {
  margin-top: 14px;
}

.error-button {
  background-color: #017993;
  padding: 8px 24px 8px 24px;
  margin-top: 24px;
  border-radius: 4px;
}

.error-link {
  color: white;
}

</style>
<script setup lang="ts">
</script>